import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

const phone = '+1 478 227 3939';
const email = 'kc@dwyer.dev';

class App extends Component {
  render() {
    return (
      <main className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            <div>Casey Dwyer</div>
            <div>Procedural Epistemologist</div>
          </p>
          <p>
            <a className="App-link" href={"tel:" + phone.replace(/ /g, '')}>{phone}</a>
            <span>&nbsp; &middot; &nbsp;</span>
            <a className="App-link" href={"mailto:" + email}>{email}</a>
          </p>
        </header>
      </main>
    );
  }
}

export default App;
